@import '../../../../styles/variables';

.#{$pdp-prefix}-buybox__quantity {
  &__list {
    margin: $andes-spacing-16 0 0 0;
    border-bottom: 1px solid $border-grey;
    color: color(color_gray_dark);

    &--options {
      max-height: 60vh;
      overflow-y: auto;
    }
  }

  &__subtitle {
    padding: 4px 16px 0;

    .ui-pdp-price__part {
      line-height: inherit;

      .price-tag-cents {
        line-height: inherit;
        padding-top: 1px;
      }
    }
  }

  &__item {
    border-top: 1px solid $border-grey;
    height: 50px;
    text-align: center;

    &--last {
      button {
        padding-left: 18px;
        text-align: left;
        width: fit-content;
      }
    }

    button {
      font-size: $font-size-16;
      height: 100%;
      width: 100%;
      color: color(color_gray_dark);
      border: none;
      background-color: transparent;

      &:focus {
        outline: none;
      }
    }

    &--label {
      display: flex;
      justify-content: space-between;
      padding: 0 18px;
      align-items: center;

      &-selected {
        font-weight: $font-weight-semibold;
      }
    }

    &--custom-options {
      height: auto;

      button {
        padding: $andes-spacing-12 0;
      }

      .andes-money-amount__suffix {
        color: $andes-text-color-primary;
      }
    }
  }

  &__item--selected {
    background-color: color(color_gray_lighter);
  }
}
