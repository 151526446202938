.#{$pdp-prefix}-container__row--outside-variations {
  margin-top: $andes-spacing-16;
}

.#{$pdp-prefix}-variations__container {
  width: 100%;
  padding: 0 $andes-spacing-16 $andes-spacing-8;
  margin-bottom: -$andes-spacing-8;

  &-bbc {
    margin-bottom: $andes-spacing-24;
  }
}

.#{$pdp-prefix}-variations {
  margin-bottom: $andes-spacing-8;

  &:last-child {
    margin-bottom: 0;
  }

  &--error {
    border: 1px solid color(color_red);

    &.#{$pdp-prefix}-action-row {
      background-color: rgba($andes-error-red, 0.05);
    }
  }

  &.#{$pdp-prefix}-action-row {
    display: flex;
    flex-direction: column;
    height: 70px;
  }

  .#{$pdp-prefix}-action-row {
    &__content {
      display: flex;
      flex-direction: row;
      margin-top: 2px;

      .andes-badge {
        margin-right: 6px;
        bottom: 1px;
      }

      .#{$pdp-prefix}-image {
        top: 19px;
      }
    }

    &__label {
      white-space: nowrap;
    }

    &__selected {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      margin-left: 0;
    }

    &__with-thumbnail {
      padding-right: 35px;
    }
  }

  &__modal,
  &__box {
    color: color(color_gray_dark);
    font-weight: $font-weight-semibold;
    padding: $andes-spacing-16;

    &--list {
      padding: 0;
    }

    a {
      &,
      &:visited,
      &:focus,
      &:hover {
        outline: none;
        color: color(color_gray_dark);
        text-decoration: none;
      }
    }

    &__variation {
      overflow: hidden;
      max-height: 300px;
      margin: 0 -16px;

      // Styles for refurbished products
      &--refurbished {
        .#{$pdp-prefix} {
          &-variations__box {
            &__variant-large__text {
              font-size: $font-size-16;
              line-height: $line-height-s;
            }

            &__stock {
              width: 74px;
            }
          }
        }
      }
    }

    // sass-lint:disable no-vendor-prefixes
    &__selected {
      margin-left: 4px;
      color: color(color_gray_dark);
      font-weight: $font-weight-semibold;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;

      .andes-badge {
        top: 3px;
        margin-right: 6px;
      }
    }

    &__variants {
      overflow: scroll hidden;
      margin-top: $andes-spacing-8;
    }

    &__variants::-webkit-scrollbar {
      display: none;
      height: 0;
    }

    &__list {
      display: flex;
      flex-flow: row nowrap;
      margin-bottom: 12px;
      align-items: stretch;
    }

    &__label,
    &__list,
    &__apparel_size_chart,
    &__subtitle {
      padding-left: 16px;
    }

    &__apparel_size_chart {
      display: flex;
      flex-direction: row;
      padding-bottom: $andes-spacing-24;

      a {
        display: flex;
        align-items: center;
      }
    }

    &__apparel_size_chart_label {
      padding-left: $andes-spacing-8;
    }

    &__error_message {
      display: flex;
      flex-direction: row;
      padding-left: $andes-spacing-8;
      align-items: center;

      &__label {
        color: $andes-error-red;
        font-weight: $font-weight-semibold;
        padding-left: $andes-spacing-4;
      }
    }

    &__label {
      display: flex;
      flex-direction: row;
      font-weight: $font-weight-regular;
    }

    &__label--with-subtitle {
      margin-bottom: 0;
    }

    &__subtitle {
      margin-bottom: $andes-spacing-8;
      font-size: $font-size-12;
      font-weight: $font-weight-regular;
    }

    &__variant {
      flex-shrink: 0;
      max-width: 80vw;

      .#{$pdp-prefix}-thumbnail__container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: $andes-spacing-12;

        &__label {
          overflow: hidden;
        }

        &__subtitle {
          font-size: $font-size-12;
          color: $andes-gray-550;
        }

        .#{$pdp-prefix}-thumbnail__label {
          padding: 0;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
        }
      }
      .#{$pdp-prefix}-thumbnail__label {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        white-space: nowrap;
      }
    }

    &__variant-large {
      border-radius: 6px;
      display: block;
      height: 190px;
      margin-right: 8px;
      padding: 14px 16px;
      text-align: left;
      width: 120px;

      .#{$pdp-prefix}-thumbnail__picture {
        height: 98px;
        width: 100%;
      }

      &__text {
        @include truncate;
      }
    }

    &__variant-large--shrunk {
      height: 93px;
    }

    &__variant-large--medium {
      height: 146px;
    }

    &__variant-fluid {
      display: block;
      width: 100%;
      height: 80px;
      border-radius: 6px;
      padding: 7px 14px;
      margin-bottom: $andes-spacing-8;
      position: relative;

      .#{$pdp-prefix}-thumbnail__picture {
        height: 100%;
        width: 65px;
        display: inline-block;
      }

      &__placeholder-right {
        position: absolute;
        right: 15px;
        top: 30px;
        width: 40px;
        height: 11px;
      }

      &__content {
        display: inline-block;
        vertical-align: top;
        margin-top: 12px;
      }

      &__text {
        position: absolute;
        right: 15px;
        top: 30px;
        max-width: 100px;
        text-align: right;
      }

      &__name {
        font-weight: $font-weight-semibold;
        font-size: $font-size-12;
      }
    }

    &__variant-short {
      font-size: $font-size-16;
      font-weight: $font-weight-regular;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__variant__price {
      font-size: $font-size-16;
      font-weight: $font-weight-semibold;
      margin: 2px 0 4px;
    }

    .#{$pdp-prefix}-price__second-line {
      transform: translateX(0);
      padding-left: unset;
    }

    .#{$pdp-prefix}-price__second-line .#{$pdp-prefix}-price__part {
      font-size: $font-size-16;
      font-weight: $font-weight-semibold;
    }

    &__button {
      width: 100%;
      margin-top: 10px;
    }

    &__quantity {
      display: flex;

      &__selector {
        display: flex;
        border-radius: $border-radius-4;
        width: 100%;
        max-width: 126px;
        height: 48px;
        border: 1px solid $andes-gray-100-solid;
        box-sizing: border-box;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

        &-input__modifier,
        &-input__screen {
          user-select: none;
          outline: none;
        }

        &-input__screen {
          text-align: center;
          flex-grow: 2;
        }

        &-input__modifier {
          flex-grow: 1;
          font-size: $font-size-16;
          background: color(color_white);
          border: none;
          text-align: center;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          .clipped {
            border: 0;
            clip: rect(0 0 0 0);
            clip-path: inset(50%);
            display: block;
            margin: 0 -1px -1px 0;
            overflow: hidden;
            padding: 0;
            position: absolute;
            z-index: -1;
          }
        }
      }

      .#{$pdp-prefix}-variations__modal-form {
        margin-left: 8px;

        .andes-button {
          margin-top: 0;
        }
      }
    }

    &-form .andes-button {
      white-space: nowrap;
    }
  }

  // Variations box styles
  &__box {
    padding: 0;

    &__list {
      margin-bottom: 0;
    }

    &__variation-separator {
      border: 0;
      margin: $andes-spacing-12 0;
    }

    &__variant-large {
      padding: $andes-spacing-12;
      width: 88px;
      height: 100%;

      .#{$pdp-prefix}-thumbnail__picture {
        height: $andes-spacing-64;
        width: $andes-spacing-64;
        padding: 0;
        margin-bottom: $andes-spacing-8;
      }

      &--refurbished {
        width: 98px;
      }
    }

    &__variant-large__line {
      margin-top: $andes-spacing-8;
    }

    .#{$pdp-prefix}-price__second-line .#{$pdp-prefix}-price__part {
      font-size: $font-size-12;
      font-weight: $font-weight-semibold;
    }

    &__variant__price {
      margin: $andes-spacing-8 0 0;
    }

    &__stock {
      width: $andes-spacing-64;
      margin: $andes-spacing-8 0 0;
      font-size: $font-size-10;
      line-height: $font-size-10;
    }

    &__label--shake {
      animation-name: shake;
      animation-duration: 1s;
      animation-timing-function: cubic-bezier(0.36, 0.07, 0.19, 0.97);
      animation-fill-mode: forwards;
      animation-delay: 0;
      transform: translate3d(0, 0, 0);
    }

    @keyframes shake {
      10%,
      90% {
        transform: translate3d(4px, 0, 0);
      }

      20%,
      80% {
        transform: translate3d(0, 0, 0);
      }

      30%,
      50%,
      70% {
        transform: translate3d(8px, 0, 0);
      }

      40%,
      60% {
        transform: translate3d(2px, 0, 0);
      }
    }
  }

  &__label-with-sizechart {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  &__subtitle-rows-container {
    margin: 12px 16px 0;
    background: #f5f5f5;
    padding: 12px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 6px;
  }

  &__selected-subtitle-row {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.andes-modal--card .#{$pdp-prefix}-variations,
.andes-modal--full .#{$pdp-prefix}-variations {
  .andes-modal__content {
    padding: 0;
  }

  &__modal__button {
    width: 100%;
    margin-top: 10px;
  }

  .andes-modal__header {
    padding: 65px 16px 20px;

    .andes-modal__close-button::before {
      left: -6px;
    }
  }
}
